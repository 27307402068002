import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "../Widget2";
import * as Icon from "../Icon";
import { dateFormat } from "../../Utils/DateUtil";
import Image from "../Image";

class ArticleGridItem extends Component {
  render() {
    let { css, item, onClick } = this.props;

    return (
      <Wrapper css={css} onClick={onClick}>
        <ImageContainer>
          <Image src={item.image} background />
        </ImageContainer>
        <DetailContainer>
          <div className="text">
            <h1>{item.name || "---"}</h1>
            {/* <p>
              {item.description.length > 200
                ? item.description.slice(0, 200) + "..."
                : item.description}
            </p> */}
          </div>
          <Widget.Col>
            <h1>{item.author || "---"}</h1>
            <div className="message">
              <span>{dateFormat(item.created)}</span>
              <div>
                <Icon.Favorite
                  color="#BF6C6C"
                  style={{ marginRight: 2, width: 20, height: 20 }}
                />
                <span>{item.favorite}</span>
              </div>
            </div>
          </Widget.Col>
        </DetailContainer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  background: white;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    max-width: 270px;
  }

  @media screen and (max-width: 414px) {
    max-width: 300px;
  }

  ${props => props.css}
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 220px;
`;

const DetailContainer = styled.div`
  padding: 15px;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 25px;

  & h1 {
    font-weight: 400px;
    font-size: 14px;
    color: #4e4e4e;
  }

  & .text {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    & > p {
      max-width: 270px;
      white-space: pre-wrap;
      font-size: 14px;
      color: #4e4e4e;
    }
  }

  & .message {
    display: flex;
    justify-content: space-between;

    & > span {
      font-size: 14px;
      color: #bfbfbf;
    }

    & > div {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #4e4e4e;
    }
  }
`;

export default ArticleGridItem;
