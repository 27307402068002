import React, { Component } from "react";
import GridItem from "../../Components/ArticleGridItem";
import ListItem from "../../Components/ArticleListItem";
import AppConfig from "../../Config";
import labels from "../../Domain/ArticleTree";
import ListingPageFactory from "../../Components/ListingPageFactory";

const moduleConfig = AppConfig["Templates/ArticleListingPage"] || {};

export default ListingPageFactory({
  layoutType: moduleConfig.layoutType,
  itemLimit: moduleConfig.itemLimit,
  cssPrefix: "articles",
  hasBreadcrumbs: true,
  css: moduleConfig.css,
  Item: moduleConfig.layoutType === "grid" ? GridItem : ListItem,
  pathPrefix: "/articles",
  detailPathPrefix: "/article",
  fetchAction: "fetchArticles",
  labels,
  sortType: "articles"
});
